<template>
  <app-form-view
    app="risk"
    model="riskprofiletemplateheader"
    api-url="risk/risk-profile-template-header/"
    :title="$t('menu.riskProfileTemplate')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepare-data="prepareData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="masterRiskProfileTemplate"
            type="select-server"
            :label="$t('fields.masterRiskProfileTemplate')"
            :view="view"
            :binds="{
              apiUrl: 'risk/master-risk-profile-template-header/?active=true'
            }"
            v-model="formData.master_risk_profile_template_header_id"
            @input="getDataFromMaster"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMasterRiskProfileTemplate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_MasterRiksProfileTemplate"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.riskProfileName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="Risk Category"
            type="select-server"
            :label="$t('fields.riskCategory')"
            :view="view"
            :binds="{
              apiUrl: 'risk/risk-category/?active=true'
            }"
            v-model="formData.category_id"
            @input="getRiskTypeItem"
            ref="riskCategory"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveRiskCategory"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_category"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="Risk Type"
            type="select-server"
            :label="$t('fields.riskType')"
            :view="view"
            :disabled="!Boolean(formData.category_id)"
            :binds="{
              apiUrl: riskTypeUrl
            }"
            v-model="formData.type_id"
            ref="riskType"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveRiskType"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_type"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="Description"
            type="html-editor"
            :label="$t('fields.description')"
            :view="view"
            v-model="formData.description"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveDescription"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_description"
          />
        </v-col>
      </v-row>
      <v-card>
        <v-tabs background-color="primary" dark>
          <v-tab>Industry/Category/Type</v-tab>
          <v-tab>Audit Program</v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    hide-edit
                    app="risk"
                    model="riskprofiletemplateindustry"
                    :headers="headers"
                    :search="search"
                    :serverItems="items"
                    :clientItems="items"
                    :loading="loading"
                    :server-items-length="serverItemsLength"
                    :hide-delete="hideDelete || mode !== 'edit'"
                    v-bind="binds"
                    v-model="selected"
                    @server="getItems"
                    @client="getClientItems"
                    @delete="onDeleteItem"
                    v-show="formData.state !== 'draft'"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view--header">
                          <v-toolbar flat>
                            <v-toolbar-title
                              >Industry/Category/Type</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <v-dialog v-model="dialog" max-width="500px">
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="selected.length && mode === 'edit'"
                                    color="error"
                                    @click="onDelete(selected)"
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="
                                            editedItem.business_industry_id
                                          "
                                          name="industry"
                                          type="select-server"
                                          :label="$t('fields.industry')"
                                          :view="view"
                                          :binds="{
                                            apiUrl:
                                              'doc-template/industry/?active=true'
                                          }"
                                          @input="getBusinessCateItem"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="
                                            editedItem.business_category_id
                                          "
                                          name="category"
                                          type="select-server"
                                          :label="$t('fields.category')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: categoryUrl
                                          }"
                                          @input="getBusinessTypeItem"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="editedItem.business_type_id"
                                          name="buztype"
                                          type="select-server"
                                          :label="$t('fields.businessType')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: buztypeUrl
                                          }"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Audit Program Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  hide-edit
                  hide-delete
                  :isSelecteable="false"
                  app="audit"
                  model="audittemplateheader"
                  :headers="auditHeader"
                  :serverItems="auditItems"
                  :clientItems="auditItems"
                  :loading="loading"
                  :server-items-length="auditItemsLength"
                  @server="getAuditHeaderItem"
                >
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppTable from '../../../../components/AppTable.vue'

export default {
  name: 'risk-profile-template-form',
  components: {
    AppInput,
    AppFormView,
    AppTable
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      approveItems: [
        'approve_name',
        'approve_MasterRiksProfileTemplate',
        'approve_category',
        'approve_type',
        'approve_description'
      ],
      riskTypeUrl: null,
      tab: null,
      auditItems: [],
      auditItemsLength: 0,
      loading: false,
      search: '',
      selected: [],
      selectedField: [],
      items: [],
      serverItemsLength: 0,
      editedItem: {
        business_industry_id: null,
        category_id: null,
        buztype_id: null
      },
      savedItem: {},
      dialog: false,
      categoryUrl: null,
      buztypeUrl: null,
      docTypeItem: []
    }
  },
  props: {
    hideEdit: {
      type: Boolean,
      default: true
    },
    hideDelete: {
      type: Boolean,
      default: false
    },
    binds: {
      type: Object,
      default: () => ({})
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    headers() {
      return [
        {
          text: this.$t('fields.businessIndustry'),
          value: 'business_industry_id.name'
        },
        {
          text: this.$t('fields.businessCategory'),
          value: 'business_category_id.name'
        },
        {
          text: this.$t('fields.businessType'),
          value: 'business_type_id.name'
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    auditHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'audit_template_header_id.name',
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'audit_template_header_id.active',
          check: true,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'riskProfileTemplateCreate'
    })
  },
  watch: {
    search(newValue, oldValue) {
      this.serverSide && newValue !== oldValue && this.onServer()
    },
    'formData.name': function (value) {
      if (this.$route.name === 'riskProfileTemplateEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'riskProfileTemplateEdit',
          text: value ?? 'N/A',
          to: {
            name: 'riskProfileTemplateEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    prepareData(data) {
      data.type_id = data.type_id.id
      data.category_id = data.category_id.id
      if (
        data.master_risk_profile_template_header_id !== null &&
        data.master_risk_profile_template_header_id !== undefined
      ) {
        data.master_risk_profile_template_header_id =
          data.master_risk_profile_template_header_id.id
      } else {
        data.master_risk_profile_template_header_id = null
      }
      return data
    },
    getAuditHeaderItem() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-program-template-risk-profile?risk_profile_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.auditItemsLength = data.count
          this.auditItems = data.results
        })
      }
      this.loading = false
    },
    getRiskTypeItem() {
      var url = ''
      if (
        this.formData.category_id !== null &&
        this.formData.category_id !== undefined
      ) {
        url =
          'risk/risk-type/?active=true&risk_category_id=' +
          this.formData.category_id.id
      } else {
        url = 'risk/risk-type/?active=true'
      }

      this.riskTypeUrl = url
    },
    onInput(value) {
      /**
       * Set input for v-model
       * @type {Event}
       */
      this.$emit('input', value)
    },
    getItems(options = null) {
      this.loading = true
      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `risk/risk-profile-template-industry?risk_profile_template_header_id_id=${this.$route.params.id}`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.serverItemsLength = data.count
          this.items = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getClientItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `risk/risk-profile-template-industry/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getItems()
        })
      })
      this.loading = false
    },
    save() {
      if (
        this.editedItem.business_industry_id !== null &&
        this.editedItem.business_industry_id !== undefined
      ) {
        this.loading = true
        this.savedItem.risk_profile_template_header_id = this.$route.params.id
        this.savedItem.business_industry_id =
          this.editedItem.business_industry_id.id
        if (
          this.editedItem.business_category_id !== null &&
          this.editedItem.business_category_id !== undefined
        ) {
          this.savedItem.business_category_id =
            this.editedItem.business_category_id.id
        }
        if (
          this.editedItem.business_type_id !== null &&
          this.editedItem.business_type_id !== undefined
        ) {
          this.savedItem.business_type_id = this.editedItem.business_type_id.id
        }
        const data = this.savedItem
        this.$api({
          method: 'post',
          url: `risk/risk-profile-template-industry/`,
          data
        })
        this.loading = false
        this.close()
        this.getItems()
      }
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    getBusinessCateItem() {
      var url = ''
      if (
        this.editedItem.business_industry_id !== null &&
        this.editedItem.business_industry_id !== undefined
      ) {
        url =
          'doc-template/category/?active=true&industry_id=' +
          this.editedItem.business_industry_id.id
      } else {
        url = 'doc-template/category/'
      }

      this.categoryUrl = url
    },
    getBusinessTypeItem() {
      var url = ''
      if (
        this.editedItem.business_category_id !== null &&
        this.editedItem.business_category_id !== undefined
      ) {
        url =
          'doc-template/buztype/?active=true&business_cate_id=' +
          this.editedItem.business_category_id.id
      } else {
        url = 'doc-template/buztype/?active=true'
      }
      this.buztypeUrl = url
    },
    getDataFromMaster() {
      this.params = {
        id: this.formData.master_risk_profile_template_header_id
      }

      if (
        this.formData.master_risk_profile_template_header_id !== null &&
        this.formData.master_risk_profile_template_header_id !== undefined
      ) {
        this.$api({
          method: 'get',
          url:
            'risk/master-risk-profile-template-header/?id=' +
            this.formData.master_risk_profile_template_header_id.id,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.$nextTick(() => {
            this.formData.category_id = data.results[0].category_id
            this.getRiskTypeItem()
            this.formData.type_id = data.results[0].type_id
            this.formData.name = data.results[0].name
            this.formData.description = data.results[0].description
            this.formData.active = data.results[0].active
            this.formData = Object.assign({}, this.formData)
            this.$refs.riskCategory.setDefaultServerItems(
              this.formData.category_id
            )
            this.$refs.riskType.setDefaultServerItems(this.formData.type_id)
          })
        })
      }
    }
  }
}
</script>
